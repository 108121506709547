import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Swiper from "react-id-swiper"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import SkuTile from "../../components/SkuTile"

import styles from "./ss22.module.scss"

const SS22 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
      "galactic-100-suede-sandals",
      "astral-suede-sandals",
      "its-trippy-suede-sandals",
      "crystalline-65-suede-pumps",
      "crystalline-100-suede-pumps",
      "luster-suede-sandals",
      "solstice-65-ombre-nappa-sandals",
      "solstice-ombre-nappa-sandals",
      "rise-up-110-ombre-nappa-sandals",
      "luna-suede-sandals",
      "mood-changer-suede-sandals",
      "harlequin-vegetable-calf-sandals",
      "lure-nappa-laminata-sandals",
      "lure-nappa-sandals",
      "console-lug-nappa-sandals",
      "console-lug-nappa-laminata-sandals",
      "borderline-10-nappa-sandals",
      "borderline-10-nappa-laminata-sandals",
      "borderline-85-nappa-sandals",
      "frontline-105-nappa-sandals",
      "frontline-105-nappa-laminata-sandals",
      "solitude-100-nappa-sandals",
      "solitude-100-nappa-laminata-sandals",
      "frontline-75-nappa-sandals",
      "frontline-75-nappa-laminata-sandals",
      "flatline-10-nappa-sandals",
      "flatline-10-nappa-laminata-sandals",
      "solstice-nappa-sandals",
    ].filter(handle => nodes.find(style => style.handle === handle))
      .map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        sku.handle = style.handle
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  const swiperParams = {
    containerClass: `${styles.swiperContainer} swiper-container`,
    WrapperEl: "ol",
    threshold: 10,
    spaceBetween: 0,
    slidesPerView: 1,
    loop: true,
    pagination: {
      el: `.swiper-pagination`,
      clickable: true,
    },
    // allowTouchMove: false,
    on: {
      slideChangeTransitionEnd: function() {
        if (this) {
          const i = this.activeIndex
          const video = this.slides[i].querySelector("video")
          if (video)
            video.play()
        }
      },
    },
  }

  const [swiper0, getSwiper0] = useState(null)
  const swiperParams0 = {
    ...swiperParams,
    getSwiper: getSwiper0,
    autoplay: true,
  }

  const [swiper1, getSwiper1] = useState(null)
  const swiperParams1 = {
    ...swiperParams,
    getSwiper: getSwiper1,
  }

  // const [swiper2, getSwiper2] = useState(null)
  const swiperParams2 = {
    ...swiperParams,
    // getSwiper: getSwiper2,
  }

  const [swiper3, getSwiper3] = useState(null)
  const swiperParams3 = {
    ...swiperParams,
    getSwiper: getSwiper3,
  }

  const [swiper4, getSwiper4] = useState(null)
  const swiperParams4 = {
    ...swiperParams,
    getSwiper: getSwiper4,
  }

  // const [swiper5, getSwiper5] = useState(null)
  const swiperParams5 = {
    ...swiperParams,
    // getSwiper: getSwiper5,
  }

  return (
    <div className={styles.ss22}>
      <SEO
        title="Spring Summer '22"
        description="New Collection"
        url={location.pathname}
      />

      <header className={styles.header}>
        <Swiper {...swiperParams0}>
          <li
            key={1}
            className={styles.swiperSlide}
          >
            <Image
              image={{
                desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/SS_landingpage_carousel1_1320x.jpg?v=1651614315",
                mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/galactic_2_14690b34-e67c-4684-9cfd-13dd4ab961e7_850x.jpg?v=1650402970",
              }}
              altText="Spring Summer 22 Image"
              className={styles.imgFullWidth}
            />
          </li>
          <li
            key={2}
            className={styles.swiperSlide}
          >
            <Image
              image={{
                desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/SS_landingpage_carousel2_1320x.jpg?v=1651614054",
                mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/SS_mobile_landingpage_carousel2_850x.jpg?v=1651614054",
              }}
              altText="Spring Summer 22 Image"
              className={styles.imgFullWidth}
            />
          </li>
          <li
            key={3}
            className={styles.swiperSlide}
          >
            <Image
              image={{
                desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/SS_landingpage_carousel3_1320x.jpg?v=1651614054",
                mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/SS_mobile_landingpage_carousel3_850x.jpg?v=1651614054",
              }}
              altText="Spring Summer 22 Image"
              className={styles.imgFullWidth}
            />
          </li>
        </Swiper>
        <button
          className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
          onClick={() => swiper0.slidePrev()}
          aria-label="Previous"
        />
        <button
          className={`${styles.swiperButtonNext} swiper-navigation-next`}
          onClick={() => swiper0.slideNext()}
          aria-label="Next"
        />
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          Spring Summer '22
        </Heading>
      </header>

      <ul className={styles.grid}>
        {allSkus.filter(sku => [
          "galactic-100-suede-sandals",
          "astral-suede-sandals",
          "its-trippy-suede-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
          <SkuTile
            key={i}
            index={i}
            style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
            sku={sku}
            customStyles={styles}
            origin={`collection-ss22`}
          />
        ))}
        <li className={styles.gridGallery1}>
          <Swiper {...swiperParams1}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/s/files/1/1103/4464/files/ASTRAL_4x5_1.mp4?v=1649956415"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/s/files/1/1103/4464/files/CRYSATALINE_100_4x5_1.mp4?v=1649956415"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper1.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper1.slideNext()}
            aria-label="Next"
          />
        </li>
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/crystalline_3_1320x.jpg?v=1650402033",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/crystalline_45_850x.jpg?v=1650402970",
        }}
        altText="Spring Summer 22 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid}>
        {allSkus.filter(sku => [
          "crystalline-65-suede-pumps",
          "crystalline-100-suede-pumps",
          "luster-suede-sandals",
          "solstice-65-ombre-nappa-sandals",
          "solstice-ombre-nappa-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-ss22`}
            />
          ))}
        <li className={styles.gridGallery2}>
          <Swiper {...swiperParams2}>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/s/files/1/1103/4464/files/LUSTER_4x5_1.mp4?v=1649956415"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
          {/*<button*/}
          {/*  className={`${styles.swiperButtonPrev} swiper-navigation-prev`}*/}
          {/*  onClick={() => swiper2.slidePrev()}*/}
          {/*/>*/}
          {/*<button*/}
          {/*  className={`${styles.swiperButtonNext} swiper-navigation-next`}*/}
          {/*  onClick={() => swiper2.slideNext()}*/}
          {/*/>*/}
        </li>
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/sunmoonstars_2_1320x.jpg?v=1650402032",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/riseup_2_439a3d9f-f860-458f-ab7b-9b828c330a0c_850x.jpg?v=1650402970",
        }}
        altText="Spring Summer 22 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid}>
        {allSkus.filter(sku => [
          "rise-up-110-ombre-nappa-sandals",
          "luna-suede-sandals",
          "mood-changer-suede-sandals",
          "harlequin-vegetable-calf-sandals",
          "lure-nappa-laminata-sandals",
          "lure-nappa-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-ss22`}
            />
          ))}
          <li className={styles.gridGallery3}>
            <Swiper {...swiperParams3}>
              <li
                key={1}
                className={styles.swiperSlide}
              >
                {// eslint-disable-next-line jsx-a11y/media-has-caption
                }
                <video autoPlay loop muted playsInline>
                  <source
                    src="https://cdn.shopify.com/s/files/1/1103/4464/files/MOODCHANGER_4x5_1.mp4?v=1649956415"
                    type="video/mp4" />
                </video>
              </li>
              <li
                key={2}
                className={styles.swiperSlide}
              >
                {// eslint-disable-next-line jsx-a11y/media-has-caption
                }
                <video autoPlay loop muted playsInline>
                  <source
                    src="https://cdn.shopify.com/s/files/1/1103/4464/files/HARLEQUIN_4x5_1.MOV?v=1650401701"
                    type="video/mp4" />
                </video>
              </li>
              <li
                key={3}
                className={styles.swiperSlide}
              >
                {// eslint-disable-next-line jsx-a11y/media-has-caption
                }
                <video autoPlay loop muted playsInline>
                  <source
                    src="https://cdn.shopify.com/s/files/1/1103/4464/files/LURE_4x5_1.mov?v=1650401701"
                    type="video/mp4" />
                </video>
              </li>
            </Swiper>
            <button
              className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
              onClick={() => swiper3.slidePrev()}
              aria-label="Previous"
            />
            <button
              className={`${styles.swiperButtonNext} swiper-navigation-next`}
              onClick={() => swiper3.slideNext()}
              aria-label="Next"
            />
          </li>
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/console_2_0311e370-28b8-4571-b142-90d5e7c5c290_1320x.jpg?v=1650402032",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/lure_2_6c049f43-3820-4401-945f-16f6be84c9a2_850x.jpg?v=1650402970",
        }}
        altText="Spring Summer 22 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid}>
        {allSkus.filter(sku => [
          "console-lug-nappa-sandals",
          "console-lug-nappa-laminata-sandals",
          "borderline-10-nappa-sandals",
          "borderline-10-nappa-laminata-sandals",
          "borderline-85-nappa-sandals",
          "frontline-105-nappa-sandals",
          "frontline-105-nappa-laminata-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-ss22`}
            />
          ))}
        <li className={styles.gridGallery4}>
          <Swiper {...swiperParams4}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/s/files/1/1103/4464/files/BORDERLINE_100_4x5_1.mp4?v=1649956415"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/s/files/1/1103/4464/files/FLATLINE_4x5_1.mov?v=1650401752"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper4.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper4.slideNext()}
            aria-label="Next"
          />
        </li>
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/solitude_1_5c6469b1-be64-4877-9c4b-d694f494e78e_1320x.jpg?v=1650402032",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/solitude_2_b7eff451-70c7-40ad-adc0-217f671dc637_850x.jpg?v=1650402970",
        }}
        altText="Spring Summer 22 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid5}>
        {allSkus.filter(sku => [
          "solitude-100-nappa-sandals",
          "solitude-100-nappa-laminata-sandals",
          "frontline-75-nappa-sandals",
          "frontline-75-nappa-laminata-sandals",
          "flatline-10-nappa-sandals",
          "flatline-10-nappa-laminata-sandals",
          "solstice-nappa-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-ss22`}
            />
          ))}
        <li className={styles.gridGallery5}>
          <Swiper {...swiperParams5}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/s/files/1/1103/4464/files/FRONTLINE_4x5_1.mp4?v=1649956418"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
          {/*<button*/}
          {/*  className={`${styles.swiperButtonPrev} swiper-navigation-prev`}*/}
          {/*  onClick={() => swiper5.slidePrev()}*/}
          {/*/>*/}
          {/*<button*/}
          {/*  className={`${styles.swiperButtonNext} swiper-navigation-next`}*/}
          {/*  onClick={() => swiper5.slideNext()}*/}
          {/*/>*/}
        </li>
      </ul>
    </div>
  )
}

export default SS22

export const query = graphql`
    query SS22Query {
        allProductStyle(filter: {handle: {in: [
          "galactic-100-suede-sandals",
          "astral-suede-sandals",
          "its-trippy-suede-sandals",
          "crystalline-65-suede-pumps",
          "crystalline-100-suede-pumps",
          "luster-suede-sandals",
          "solstice-65-ombre-nappa-sandals",
          "solstice-ombre-nappa-sandals",
          "rise-up-110-ombre-nappa-sandals",
          "luna-suede-sandals",
          "mood-changer-suede-sandals",
          "harlequin-vegetable-calf-sandals",
          "lure-nappa-laminata-sandals",
          "lure-nappa-sandals",
          "console-lug-nappa-sandals",
          "console-lug-nappa-laminata-sandals",
          "borderline-10-nappa-sandals",
          "borderline-10-nappa-laminata-sandals",
          "borderline-85-nappa-sandals",
          "frontline-105-nappa-sandals",
          "frontline-105-nappa-laminata-sandals",
          "solitude-100-nappa-sandals",
          "solitude-100-nappa-laminata-sandals",
          "frontline-75-nappa-sandals",
          "frontline-75-nappa-laminata-sandals",
          "flatline-10-nappa-sandals",
          "flatline-10-nappa-laminata-sandals",
          "solstice-nappa-sandals",
        ]}}) {
          nodes {
            ...PLPStyleFragment
            handle
          }
        }
    }
`
